import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { axiosInstance } from "../App";

const pages = [
  { name: "Line of fortune", route: "/LineOfFortune" },
  { name: "Jackpot", route: "/Jackpot" },
  { name: "Inventory", route: "/Inventory" },
];
const settings = ["Profile", "Rewards", "Support", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //is called on userInfoChanged
    setLoggedIn(!!userInfo);
  }, [userInfo]);

  useEffect(() => {
    //is called every render
    // console.log(loggedIn, userInfo);
  });

  useEffect(() => {
    //is called on ~mount
    axiosInstance
      .get("/user/info")
      .then((json) => {
        setUserInfo(json.data);
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleUserMenuItemClick = (setting) => {
    if (setting === "Logout") {
      // Log the user out
      axiosInstance
        .get("/auth/logout")
        .then(() => {
          setUserInfo(null);
        })
        .catch((error) => {
          console.error("Error logging out:", error);
        });
    } else if (setting === "Profile") {
      navigate("/Profile");
    }
    handleCloseUserMenu();
  };
  const handleLoginAction = () => {
    // Redirect the user to the Steam login page
    window.location.href =
      axiosInstance.getUri() + `/auth/login?returnUrl=${window.location.href}`;
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={require("../images/Casino.png")}
            alt="Logo"
            style={{
              width: "40px",
              height: "auto",
              display: { xs: "none", md: "flex" },
              marginRight: "1em",
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            text
            onClick={() => navigate("/")}
            sx={{
              mr: 4,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            RAJONO BETS
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <CustomLink to={page.route}>{page.name}</CustomLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            RAJONO BETS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <CustomLink
                key={index}
                to={page.route}
                onClick={handleCloseNavMenu}
              >
                {page.name}
              </CustomLink>
            ))}
          </Box>
          {loggedIn ? (
            <>
              <Box mr="6px">
                <Typography textAlign="center">{userInfo?.realname}</Typography>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={userInfo?.personname} src={userInfo?.avatar} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleUserMenuItemClick(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          ) : (
            <Button color="inherit" onClick={handleLoginAction}>
              Login
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link to={to} {...props}>
      <Button
        className={isActive ? "active" : ""}
        sx={{ my: 2, color: "White", display: "block" }}
      >
        {children}
      </Button>
    </Link>
  );
}
