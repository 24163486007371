import React from "react";
import Home from "./pages/Home";
import Header from "./pages/Header2.0";
import JackPot from "./pages/JackPot";
import Inventory from "./pages/Inventory";
import Profile from "./pages/Profile";
import Roullete from "./pages/Roullete";
import { Route, Routes } from "react-router-dom";
import axios from "axios";

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

function App() {
  return (
    <div>
      <Header />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/JackPot" element={<JackPot />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Inventory" element={<Inventory />} />
          <Route path="/LineOfFortune" element={<Roullete />} />
        </Routes>
      </div>
    </div>
  );
}
export default App;
