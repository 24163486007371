import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { axiosInstance } from "../App";

function Profile() {
  //todo: move to redux or somewhere else
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    axiosInstance
      .get("/user/info")
      .then((json) => {
        setUserInfo(json.data);
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }, []);

  return (
    <div className="profile">
      <h1>Profile</h1>
      {!!userInfo ? (
        <>
          <h2>{userInfo.realname}</h2>
          {Object.entries(userInfo).map(([item, value]) => (
            <Typography>
              {item}: {value}
            </Typography>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Profile;
