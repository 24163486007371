import React, { useState } from "react";
import "../styles.css"; // import your CSS file

function Inventory() {
  const [skins] = useState([
    {
      name: "AK-47 | Redline",
      rarity: "Classified",
      price: 15.99,
      wear: "Field-Tested",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
    {
      name: "AWP | Asiimov",
      rarity: "Covert",
      price: 50.99,
      wear: "Minimal Wear",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
    {
      name: "M4A1-S | Hyper Beast",
      rarity: "Classified",
      price: 20.99,
      wear: "Well-Worn",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
    {
      name: "USP-S | Orion",
      rarity: "Restricted",
      price: 5.99,
      wear: "Factory New",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
    {
      name: "P250 | Boreal Forest",
      rarity: "Mil-Spec",
      price: 0.99,
      wear: "Field-Tested",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
    {
      name: "Glock-18 | Candy Apple",
      rarity: "Consumer Grade",
      price: 0.05,
      wear: "Battle-Scarred",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
    {
      name: "Bayonet | Doppler",
      rarity: "Contraband",
      price: 100.99,
      wear: "Factory New",
      image:
        "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv3--Y3nj1H6qBFvMWHyIo7Adw9raF6GrlK9lLi-jJO7tJ_JzyNh63F3sX_emUPkgQYMMLL2MDRAbQ/360fx360f",
    },
  ]);
  const [selectedSkin, setSelectedSkin] = useState(null);

  const handleSelected = (index) => {
    const skin = skins[index];
    setSelectedSkin(skin);
  };

  return (
    <div className="inventory">
      <div className="skin-list">
        {skins.map((skin, index) => (
          <div
            key={index}
            className={`inventory-item ${skin.rarity.toLowerCase()}`}
            onClick={() => handleSelected(index)}
          >
            <img
              src={skin.image}
              alt=""
              style={{
                width: "80px",
                height: "auto",
                display: { xs: "none", md: "flex" },
                marginRight: "1em",
              }}
            />
            <h2>{skin.name}</h2>
            <p>Price: ${skin.price}</p>
          </div>
        ))}
      </div>
      <div className="selected-skin">
        {selectedSkin && (
          <div
            className={`inventory-item ${selectedSkin.rarity.toLowerCase()}`}
          >
            <img
              src={selectedSkin.image}
              alt=""
              style={{ width: "150px", height: "auto" }}
            />
            <h2>{selectedSkin.name}</h2>
            <p>Price: ${selectedSkin.price}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Inventory;
